var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-guard-top-domains-wrapper" }, [
    _vm.topBlockedDomains && !_vm.hideBlockedDomains
      ? _c(
          "div",
          { staticClass: "sec blocked-domains" },
          [
            _c("h3", { staticClass: "sub-title" }, [
              _vm._v("Top Blocked Domains"),
            ]),
            _vm._m(0),
            _vm._l(_vm.topBlockedDomains, function (domain, ind) {
              return _c("div", { key: ind, staticClass: "row" }, [
                _c("span", { staticClass: "cell domain" }, [
                  _vm._v(_vm._s(domain.name)),
                ]),
                _c("span", { staticClass: "cell count" }, [
                  _vm._v(_vm._s(domain.count)),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.topQueriedDomains && !_vm.hideQueriedDomains
      ? _c(
          "div",
          { staticClass: "sec blocked-domains" },
          [
            _c("h3", { staticClass: "sub-title" }, [
              _vm._v("Top Queried Domains"),
            ]),
            _vm._m(1),
            _vm._l(_vm.topQueriedDomains, function (domain, ind) {
              return _c("div", { key: ind, staticClass: "row" }, [
                _c("span", { staticClass: "cell domain" }, [
                  _vm._v(_vm._s(domain.name)),
                ]),
                _c("span", { staticClass: "cell count" }, [
                  _vm._v(_vm._s(domain.count)),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row title-row" }, [
      _c("span", { staticClass: "cell domain" }, [_vm._v("Domain")]),
      _c("span", { staticClass: "cell" }, [_vm._v("Query Count")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row title-row" }, [
      _c("span", { staticClass: "cell domain" }, [_vm._v("Domain")]),
      _c("span", { staticClass: "cell" }, [_vm._v("Query Count")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }